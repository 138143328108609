import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Box sx={{ padding: 2, textAlign: 'center', backgroundColor: '#f5f5f5' }}>
      <Typography variant="body1">
        © {new Date().getFullYear()} - {t('footer.rights')}
      </Typography>
    </Box>
  );
};

export default Footer;

import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './HeroSection.css'
const HeroSection = () => {
  const {t} = useTranslation();
  function scrollToAnchor(event,anchorId) {
    event.preventDefault()
    const element = document.getElementById(anchorId);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth', // Enables smooth scrolling
        block: 'center',    // Aligns the anchor element to the center of the viewport
      });
    }
  }
  return (
    <Box sx={{ position: 'relative', height: { xs: '500px', sm: '400px', md: '500px', lg: '600px' }, overflow: 'hidden', width: '100%' }} id="home">
      <img
        src="https://images.thebusinessplanshop.com/ouvrir-entreprise-demenagement/ouvrir-une-entreprise-de-demenagement.jpg" // Replace with your image URL
        alt="A moving truck loading items" // Update with a relevant description
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 0,
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(50, 50, 50, 0.3)',
          zIndex: 1,
        }}
      />
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          color: 'white',
          padding:'10px',
          
        }}
      >
        <Typography variant="h2" gutterBottom sx={{textAlign:'center',fontSize:{xs: '45px', sm: '50px', md:'55px'}}}>
          {t('hero.bigTitle')}
        </Typography>
        {/* <Typography variant="h6" gutterBottom>
        {t('hero.smallTitle')}
        </Typography> */}
        <Button variant="contained" color="primary" onClick={(e)=>scrollToAnchor(e,'estimate')} className='heroSection-button' sx={{color:'white', fontSize:'20px'}}>
        {t('hero.button')}
        </Button>
      </Box>
    </Box>
  );
};

export default HeroSection;

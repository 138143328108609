import { createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: { main: '#009F97' },
    secondary: { main: '#93BF20' },
    background: { default: '#ffffff', paper: '#f5f5f5' },
    text: { primary: '#000000' },
  },
  typography: {
    fontFamily: "'League Spartan', 'Quicksand', Arial, sans-serif",
    h1: {
      fontFamily: "'League Spartan', sans-serif",
      fontWeight: 700,
    },
    h2: {
      fontFamily: "'League Spartan', sans-serif",
      fontWeight: 700,
    },
    body1: {
      fontFamily: "'Quicksand', sans-serif",
      fontWeight: 400,
    },
    body2: {
      fontFamily: "'Quicksand', sans-serif",
      fontWeight: 500,
    },
  },
});

export default lightTheme;

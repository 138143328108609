import React, { useState, forwardRef, useImperativeHandle } from 'react';
import {
  Box, Button, Container, Typography, FormControl, Checkbox,
  FormControlLabel, Alert, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, IconButton, Paper, TextField,
  Tooltip
} from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline, DeleteOutline, Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const categorizedFurnitureItems = {
  Furniture: [
    { id: 1, name: 'desk', volume: 0.6 },
    { id: 2, name: 'office_chair', volume: 0.3 },
    { id: 5, name: 'conference_table', volume: 2 },
    { id: 11, name: "small_cabinet", volume: 0.4 },
    { id: 12, name: "whiteboard", volume: 0.2 },
    { id: 13, name: "coffee_table", volume: 0.3 },
    { id: 14, name: "trash_bin", volume: 0.05 },
    { id: 15, name: "water_cooler", volume: 0.25 },
    { id: 20, name: "office_lamp", volume: 0.03 },
    { id: 23, name: "drawer_unit", volume: 0.2 },
    { id: 26, name: "low_cabinet", volume: 0.9 },
    { id: 27, name: "high_cabinet", volume: 1.3 },
    { id: 28, name: "sofa", volume: 1.8 },
    { id: 29, name: "armchair", volume: 0.5 },
    { id: 30, name: "chair_or_stool", volume: 0.3 },
    { id: 45, name: 'desk_lamp', volume: 0.2 },
    { id: 46, name: 'rug_or_frames', volume: 0.3 },
    { id: 49, name: 'bulk_waste', volume: 0.27 },


    // Add more furniture items
  ],
  Electronics: [
    { id: 7, name: 'computer_monitor', volume: 0.3 },
    { id: 8, name: 'desktop_computer', volume: 0.1 },
    { id: 9, name: "printer", volume: 0.3 },
    { id: 10, name: "photocopier", volume: 1 },
    { id: 36, name: 'server', volume: 0.4 },
    { id: 37, name: 'tv_or_screen', volume: 0.3 },
    { id: 33, name: "central_unit", volume: 0.1 },
    { id: 34, name: "small_fridge", volume: 0.6 },
    { id: 35, name: "large_fridge", volume: 1.3 },
    { id: 38, name: "small_equipment", volume: 0.3 },
    { id: 55, name: "shredder", volume: 1.5 }
    // Add more electronics
  ],

  Other: [

    { id: 47, name: "paper_boxes", volume: 0.1 },
    { id: 48, name: "ink_cartridges", volume: 0.13 },
    // Add more items in other category
  ],
  IndustrialWaste: [
    { id: 44, name: 'pallets', volume: 0.3 },
    { id: 42, name: "office_partition", volume: 0.2 },
    { id: 43, name: "shelves_and_boards", volume: 0.2 },
    { id: 50, name: "rubble_bags", volume: 0.1 },
    { id: 51, name: "false_ceiling", volume: 0.15 },
    { id: 52, name: "carpet", volume: 0.4 },
    { id: 53, name: "glass", volume: 0.15 },
    { id: 54, name: "construction_waste", volume: 0.1 }


    // Add more industrial waste items
  ],
};
const furnitureItems = Object.entries(categorizedFurnitureItems).flatMap(([category, items]) =>
  items.map(item => ({ ...item, category }))
);
const EstimationForm = forwardRef((props, ref) => {
  const [furnitureSelection, setFurnitureSelection] = useState(
    furnitureItems.map(item => ({ ...item, quantity: 0, fade: false, photo: null }))
  );
  const [formData, setFormData] = useState({ floor: '0', elevator: false });
  const [error, setError] = useState('');
  // const [estimatedPrice, setEstimatedPrice] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const calculateVolume = () => {
    return furnitureSelection.reduce((total, item) => total + (item.volume * item.quantity), 0);
  };
  const { t } = useTranslation()
  const calculatePrice = () => {
    const totalVolume = calculateVolume();
    const floorPrice = parseInt(formData.floor) * 5;
    const elevatorDiscount = formData.elevator ? 0.4 : 1;
    //   const departurePrice = totalVolume>=3?50:0
    return (Math.ceil(totalVolume * (40 + floorPrice * elevatorDiscount)));
  };

  useImperativeHandle(ref, () => ({
    calculatePrice,
    handleSubmit
  }));

  const handleSubmit = (e) => {
    e.preventDefault();

    // Create an object with the data to pass to the parent
    const estimationData = {
      furniture: furnitureSelection.filter(item => item.quantity > 0), // Only include selected items
      floor: formData.floor,
      elevator: formData.elevator
    };


    // Call the parent's data change function
    props.onDataChange(estimationData);

    setError('');
  };
  const handleQuantityChange = (id, delta) => {
    const newFurnitureSelection = furnitureSelection.map(item => {
      if (item.id === id) {
        const newQuantity = Math.max(0, item.quantity + delta);
        return { ...item, quantity: newQuantity, fade: newQuantity === 0 ? true : false };
      }
      return item;
    });
    setFurnitureSelection(newFurnitureSelection);
  };

  const resetQuantity = (id) => {
    const newFurnitureSelection = furnitureSelection.map(item =>
      item.id === id ? { ...item, quantity: 0, fade: true } : item
    );
    setFurnitureSelection(newFurnitureSelection);
  };

  // const validateForm = () => {
  //   const totalVolume = calculateVolume();
  //   if (totalVolume === 0) return t("estimate.furniture_error");
  //   if (!formData.floor) return t("estimate.floor_error");
  //   return '';
  // };


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
  };

  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = () => setOpenDialog(false);

  const uploadImageToCloudinary = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', 'ml_default'); // Set your Cloudinary preset

    return fetch(`https://api.cloudinary.com/v1_1/dgelnwfcv/image/upload`, {
      method: 'POST',
      body: formData,
    })
      .then(response => response.json())
      .then(data => data.secure_url)
      .catch(error => {
        console.error('Image upload failed:', error);
        setError('Image upload failed');
      });
  };

  // Function to handle image upload and update the furniture selection
  const handleImageUpload = async (id, file) => {
    const imageUrl = await uploadImageToCloudinary(file);
    const newFurnitureSelection = furnitureSelection.map(item => {
      if (item.id === id) {
        return { ...item, photo: imageUrl }; // Store the Cloudinary image URL
      }
      return item;
    });
    setFurnitureSelection(newFurnitureSelection);
  };


  const handleRemoveImage = (id) => {
    const newFurnitureSelection = furnitureSelection.map(item => {
      if (item.id === id) {
        return { ...item, photo: null }; // Remove the image
      }
      return item;
    });
    setFurnitureSelection(newFurnitureSelection);
  };

  return (
    <Container maxWidth="md" sx={{ marginTop: '50px', marginBottom: '50px' }}>
      {error && <Alert severity="error">{error}</Alert>}

      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3, display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        {/* Furniture Selection Button */}
        <Button variant="contained" color="primary" onClick={handleDialogOpen} sx={{ alignSelf: 'center' }}>
          {t("estimate.choose_furniture")}
        </Button>

        {/* Dialog for selecting furniture */}
        <Dialog
          fullWidth
          open={openDialog}
          onClose={handleDialogClose}
          maxWidth="md"
          PaperProps={{
            sx: {
              width: '90%',
              maxWidth: '1000px',
              overflow: 'auto', // Dialog spans the entire width
              height: { xs: '90vh', sm: 'auto' }, // Sets height for smaller screens

            }
          }}>
          <DialogTitle>{t("estimate.choose_furniture")}</DialogTitle>
          <DialogContent sx={{ px: { xs: 1, sm: 3 }, py: { xs: 2, sm: 3 } }}>
            <TableContainer component={Paper} sx={{
              maxWidth: '100%',
              overflowX: 'auto', // Enables horizontal scrolling for small screens
              marginTop: 2,
            }}>
              <Table sx={{
                minWidth: 600, // Ensures table layout for larger screens
                tableLayout: { xs: 'auto', sm: 'fixed' }, // Adjusts layout based on screen size
              }} aria-label="furniture selection table">
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>{t("estimate.furniture")}</TableCell>
                    <TableCell align="center">{t("estimate.quantity")}</TableCell>
                    <TableCell align="center">Actions</TableCell>
                    <TableCell align="center">Photo</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(categorizedFurnitureItems).map(([category, items]) => (
                    <React.Fragment key={category}>
                      <TableRow>
                        <TableCell colSpan={4} sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0', textAlign: 'center' }}>
                          {t(`estimate.${category.toLowerCase()}`)} {/* Translate the category name */}
                        </TableCell>
                      </TableRow>
                      {items.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell align='center'>{t("furniture." + item.name)}</TableCell>
                          <TableCell align="center">{furnitureSelection.find(f => f.id === item.id)?.quantity || 0}</TableCell>
                          <TableCell align="center">
                            <IconButton onClick={() => handleQuantityChange(item.id, 1)} aria-label="add">
                              <AddCircleOutline />
                            </IconButton>
                            <IconButton onClick={() => handleQuantityChange(item.id, -1)} aria-label="remove">
                              <RemoveCircleOutline />
                            </IconButton>
                            <IconButton onClick={() => resetQuantity(item.id)} aria-label="reset">
                              <DeleteOutline />
                            </IconButton>
                          </TableCell>
                          <TableCell align="center">
                            {furnitureSelection.find(f => f.id === item.id)?.photo ? (
                              <Box position="relative" display="inline-block">
                                <img
                                  src={furnitureSelection.find(f => f.id === item.id)?.photo}
                                  alt={`${item.name}`}
                                  style={{ width: 50, height: 50, marginLeft: 5, border: '1px solid #ccc' }}
                                />
                                <IconButton
                                  sx={{
                                    position: 'absolute',
                                    top: -5,
                                    right: -5,
                                    backgroundColor: 'red',
                                    color: 'white',
                                    padding: '4px',
                                    '&:hover': {
                                      backgroundColor: 'darkred',
                                    }
                                  }}
                                  onClick={() => handleRemoveImage(item.id)}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              </Box>
                            ) : (
                              <>
                                <input
                                  accept="image/*"
                                  style={{ display: 'none' }}
                                  id={`upload-photo-${item.id}`}
                                  type="file"
                                  onChange={(e) => handleImageUpload(item.id, e.target.files[0])}
                                />
                                <label htmlFor={`upload-photo-${item.id}`}>
                                  <Button variant="outlined" component="span">
                                    Upload
                                  </Button>
                                </label>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Done
            </Button>
          </DialogActions>
        </Dialog>

        {/* Table to display selected furniture */}
        {furnitureSelection.some(item => item.quantity > 0) && (
          <TableContainer component={Paper} sx={{ mt: 4, maxWidth: '100%', mx: 'auto', border: 1, borderRadius: 2, borderColor: '#ddd', boxShadow: 2 }}>
            <Table size="small" sx={{ backgroundColor: '#f9f9f9', minWidth: 465 }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold', color: '#333', backgroundColor: '#ddd' }}>{t("estimate.furniture")}</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 'bold', color: '#333', backgroundColor: '#ddd' }}>{t("estimate.quantity")}</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', color: '#333', backgroundColor: '#ddd' }}>Photo</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', color: '#333', backgroundColor: '#ddd' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {furnitureSelection.filter(item => item.quantity > 0).map((item) => (
                  <TableRow key={item.id} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f0f0f0' } }}>
                    <TableCell>{t("furniture." + item.name)}</TableCell>
                    <TableCell align="right">{item.quantity}</TableCell>
                    <TableCell align="center">
                      {item.photo && (
                        <Box position="relative" display="inline-block">
                          <img
                            src={item.photo}
                            alt={`${item.name}`}
                            style={{ width: 50, height: 50, border: '1px solid #ccc' }}
                          />
                          <IconButton
                            sx={{
                              position: 'absolute',
                              top: -5,
                              right: -5,
                              backgroundColor: 'red',
                              color: 'white',
                              padding: '4px',
                              '&:hover': {
                                backgroundColor: 'darkred',
                              }
                            }}
                            onClick={() => handleRemoveImage(item.id)}
                          >
                            <Close fontSize="small" />
                          </IconButton>
                        </Box>
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <Box sx={{ alignItems: 'center', alignContent: 'center' }}>
                        <IconButton onClick={() => handleQuantityChange(item.id, 1)} aria-label="add">
                          <AddCircleOutline />
                        </IconButton>
                        <IconButton onClick={() => handleQuantityChange(item.id, -1)} aria-label="remove">
                          <RemoveCircleOutline />
                        </IconButton>
                        <IconButton onClick={() => resetQuantity(item.id)} aria-label="reset">
                          <DeleteOutline />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

          {/* Floor Label */}
          <Typography variant="h5" gutterBottom sx={{ marginRight: '30px', paddingTop: '14px' }}>
            {t("estimate.floor")}
          </Typography>

          {/* Floor Input with +/- Buttons */}
          <FormControl>
            <Box
              display="flex"
              alignItems="center"
              sx={{ mt: 2 }}
            >
              {/* Decrease button */}
              <IconButton
                onClick={() => setFormData(prev => ({ ...prev, floor: Math.max(0, prev.floor - 1) }))}
                size="small"
                aria-label="decrease floor"
                sx={{
                  backgroundColor: '#f0f0f0',
                  borderRadius: '50%',
                  ':hover': { backgroundColor: '#d9d9d9' }
                }}
              >
                <RemoveCircleOutline fontSize="small" />
              </IconButton>

              {/* Numeric Input Field */}
              <TextField
                id="floor-input"
                name="floor"
                value={formData.floor}
                onChange={(e) => {
                  const value = Math.max(0, Math.min(100, Number(e.target.value))); // Limit between 0 and 100
                  setFormData({ ...formData, floor: value });
                }}
                type="text"  // Set to 'text' to disable default HTML number arrows
                inputProps={{
                  inputMode: 'numeric',  // Optimizes for numeric input
                  pattern: '[0-9]*',     // Only allows numeric input
                  min: 0, max: 100
                }}
                sx={{
                  width: 80,
                  textAlign: 'center',
                  mx: 1, // Adds margin between the buttons
                  borderRadius: '20px', // Rounded edges
                  '& input': {
                    textAlign: 'center',
                    WebkitAppearance: 'none', // Disables default arrows in WebKit browsers
                    MozAppearance: 'textfield', // Disables default arrows in Firefox
                  },
                  '& fieldset': { borderRadius: '20px' } // Rounded edges for the border
                }}
              />

              {/* Increase button */}
              <IconButton
                onClick={() => setFormData(prev => ({ ...prev, floor: Math.min(100, prev.floor + 1) }))}
                size="small"
                aria-label="increase floor"
                sx={{
                  backgroundColor: '#f0f0f0',
                  borderRadius: '50%',
                  ':hover': { backgroundColor: '#d9d9d9' }
                }}
              >
                <AddCircleOutline fontSize="small" />
              </IconButton>
            </Box>
          </FormControl>

          {/* Elevator Checkbox */}


        </Box>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formData.elevator}
                onChange={handleChange}
                name="elevator"
                color="primary"
              />
            }
            label={t("estimate.elevator")}
            sx={{ ml: 3 }}
          />

          <Tooltip
            title="decription of operable elevator"
            sx={{}}

          ><InfoOutlinedIcon sx={{ paddingTop: '20px', marginRight: '5px', color: 'gray', transform: 'translate(-40%,-20%)' }} /></Tooltip>
        </div>

        <Button variant='contained' onClick={handleSubmit}>{t("estimate.confirm")}</Button>
      </Box>
    </Container>
  );
});

export default EstimationForm;
import React from 'react';
import Slider from 'react-slick';
import { Box, Typography, Card, CardContent, Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Sample avatar images for the testimonials
const avatars = {
  "Marie D.": "/path/to/marie-avatar.jpg",
  "Jean-Pierre L.": "/path/to/jean-pierre-avatar.jpg",
  "Sophie M.": "/path/to/sophie-avatar.jpg",
  "Antoine R.": "/path/to/antoine-avatar.jpg",
  "Élodie T.": "/path/to/elodie-avatar.jpg",
};

const Testimonials = () => {
  const { t } = useTranslation();
  const testimonialsData = t('testimonials.testimonials', { returnObjects: true });

  // Slider settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Show only one card at a time
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2, // Adjusted for larger screens
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (

    <Box sx={{ padding: 4, backgroundColor: '#f9f9f9', textAlign: 'center', width: '70%', justifySelf: 'center', my: '30px' }} id='testimonials'>
      <Typography variant="h4" gutterBottom>
        {t("testimonials.title")}
      </Typography>
      <Typography variant="h6" paragraph>
        {t("testimonials.subtitle")}
      </Typography>
      <Slider {...settings}>
        {testimonialsData.map((testimonial, index) => (
          <Card
            key={index}
            sx={{
              margin: '0 auto',
              padding: 3,
              boxShadow: 3,
              maxWidth: { xs: '100%', sm: 350 },
              borderRadius: 2,
              transition: 'transform 0.3s, box-shadow 0.3s',
              '&:hover': {
                transform: 'scale(1.05)', // Slightly scale the card on hover
                boxShadow: 6, // Increase shadow on hover
              },
            }}
          >
            <CardContent sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              alignItems: 'center',
              textAlign: { xs: 'center', sm: 'left' },
            }}>
              <Avatar src={avatars[testimonial.name]} sx={{ width: 56, height: 56, marginRight: 2 }} />
              <div>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{testimonial.name}</Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  {testimonial.title}
                </Typography>
                <Typography variant='body1' fontWeight='bold'>{testimonial.role}</Typography>
                <Typography variant="body2" sx={{ marginTop: 1, fontStyle: 'italic', color: '#555' }}>
                  "{testimonial.quote}"
                </Typography>
              </div>
            </CardContent>
          </Card>
        ))}
      </Slider>
    </Box>
  );
};

export default Testimonials;

import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import './OurProcess.css'; // Import the CSS file
import { HashLink } from 'react-router-hash-link';

// Adjusted Section styling for more space and free-flowing design
const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 0),
  textAlign: 'center',
}));

// Background colors for each section with ample spacing
const StepSection = styled(Box)(({ theme, bgColor }) => ({
  backgroundColor: bgColor || '#ffffff',
  fontSize: '72px',
  padding: theme.spacing(8, 5), // Increased padding for a spacious feel
  margin: theme.spacing(3, 0),   // Increased margin between sections
  borderRadius: theme.shape.borderRadius,
}));

// const StepNumber = styled(Box)(({ theme }) => ({
//   fontSize: '48px',
//   fontWeight: 'bold',
//   color: 'green',
//   marginBottom: theme.spacing(2),
// }));
const StepImage = styled('img')(({ theme }) => ({
  width: '10%',             // Adjust to fit the section width
  maxWidth: '100px',          // Optional max width for large screens
  marginTop: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
}));

const OurProcess = () => {
  const { t } = useTranslation();
  const steps = t('our_process.steps', { returnObjects: true });
  const [fadeInClasses, setFadeInClasses] = useState(Array(steps.length).fill(''));

  // Background colors for each section
  const backgroundColors = ['#ffffff', '#f0f0f0', '#d1e8e2', '#cce4f7'];
  const images = ['contact', 'needs', 'quote', 'scheduling','payment' ,'removal', 'recycling', 'followup']
  useEffect(() => {
    // Set fade-in classes for each section
    const newClasses = steps.map((_, index) => {
      return index % 2 === 0 ? 'fade-in-left' : 'fade-in-right';
    });
    setFadeInClasses(newClasses);
  }, [steps]);

  // Exit early if steps is not populated
  if (!steps.length) {
    return null;
  }

  return (
    <Box>
      <Section id="process">
        <Typography variant="h4" gutterBottom>
          {t('our_process.title')}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" paragraph>
          {t('our_process.subtitle')}
        </Typography>
      </Section>

      {steps.map((step, index) => (
        <StepSection
          key={index}
          bgColor={backgroundColors[index % backgroundColors.length]}
          className={fadeInClasses[index]}
          sx={{ alignItems: 'center', justifyContent: 'center', alignContent: 'center' }} // Apply the fade-in class
        >
          <Typography variant="h5" gutterBottom>{step.title}</Typography>
          <Typography variant="body1" color="textSecondary" sx={{ marginTop: 2, maxWidth: '600px', margin: 'auto' }}>
            {step.description}
          </Typography>
          <StepImage src={require('../assets/' + images[index] + '.png')} alt={step.title} sx={{ position: 'relative', float: 'inline-end', transform: 'translate(-100%,-100%)' }} />
        </StepSection>
      ))}

      <Box textAlign="center" mt={8} mb={6}>
        <HashLink smooth to="/#process">

          <Button variant="contained" color="primary" size="large" href="/#contact">
            {t('our_process.cta')}
          </Button>
          </HashLink>
      </Box>
    </Box>
  );
};

export default OurProcess;

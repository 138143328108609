import React, { useState, useRef } from 'react';
import {  Typography, Button, Stepper, Step, StepLabel, TextField, Alert, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import emailjs from 'emailjs-com'; // Import EmailJS
import EstimationForm from './EstimationForm';

const steps = ['start', 'enter_postal_codes', 'select_furniture', 'additional_details'];

function getIleDeFrancePostalCodes() {
    const ileDeFranceDepartments = {
        '60': "osie",
        '75': 'Paris',
        '77': 'Seine-et-Marne',
        '78': 'Yvelines',
        '91': 'Essonne',
        '92': 'Hauts-de-Seine',
        '93': 'Seine-Saint-Denis',
        '94': 'Val-de-Marne',
        '95': 'Val-d\'Oise',
    };

    let postalCodes = [];

    for (const departmentCode in ileDeFranceDepartments) {
        for (let i = 0; i <= 999; i++) {
            const postalCode = departmentCode + ( i>9 && i<100 ? '0' : '')+(i<10?'00':'') +i;
            postalCodes.push(postalCode);
        }
    }

    return postalCodes;
}
const validPostalCodes = getIleDeFrancePostalCodes();

const EstimationSection = () => {
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState(0);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [formData, setFormData] = useState({
        choice: '',
        pickUpPostalCode: '',
        depositPostalCode: '',
        furniture: [],
        elevator: false,
        floor: '',
        fullName: '',
        phoneNumber: '',
        emailAddress: '',
        completeAddressPickUp: '',
        completeAddressDeposit: '',
        estimatedPrice: null,
        personType: ''
    });
    const [errors, setErrors] = useState({});
    const [estimatedPrice, setEstimatedPrice] = useState(null);
    const [submitted, setSubmitted] = useState(null)
    const estimationFormRef = useRef(null);

    const handleEstimationData = (newFurnitureData) => {
        setFormData(prev => ({
            ...prev,
            furniture: newFurnitureData.furniture,
            floor: newFurnitureData.floor,
            elevator: newFurnitureData.elevator
        }));
        setIsConfirmed(true);
    };

    const isValidPostalCode = (code) => {
        const regex = /^\d{5}$/;
        return regex.test(code) && validPostalCodes.includes(code);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    // const handleChoice = (selectedChoice) => {
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         choice: selectedChoice
    //     }));
    //     setActiveStep((prevStep) => prevStep + 1);
    // };



    const handleNext = () => {
        let validationErrors = {};

        if (!isValidPostalCode(formData.pickUpPostalCode)) {
            validationErrors.pickUpPostalCode = t("estimate.invalid_postal_code");
        }

        if (formData.choice === 'move' && !isValidPostalCode(formData.depositPostalCode)) {
            validationErrors.depositPostalCode = t("estimate.invalid_postal_code");
        }

        if (Object.keys(validationErrors).length === 0) {
            setErrors({});
            setActiveStep((prevStep) => prevStep + 1);
        } else {
            setErrors(validationErrors);
        }
    };

    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handleEstimate = () => {
        if (estimationFormRef.current) {
            const price = estimationFormRef.current.calculatePrice();
            setEstimatedPrice(price);
            formData.estimatedPrice = price
        }
    };

    const handleRequestQuote = () => {
        setActiveStep((prevStep) => prevStep + 1);
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission

        const emailData = {
            service_id: 'service_7j8oe9y', // Replace with your EmailJS service ID
            template_id: 'template_fkrkfym', // Replace with your EmailJS template ID
            user_id: 'l9-W6dMyV2juDfhtY', // Replace with your EmailJS user ID
            template_params: {
                fullName: formData.fullName,
                phoneNumber: formData.phoneNumber,
                emailAddress: formData.emailAddress,
                completeAddressPickUp: formData.completeAddressPickUp,
                completeAddressDeposit: formData.completeAddressDeposit,
                estimatedPrice: formData.estimatedPrice,
                furnitureList: formData.furniture.map((e) => ({
                    ...e,  // Spread the existing object properties
                    name: t('furniture.' + e.name),  // Translate the furniture name
                })),
                personType: formData.personType,
                companyName: formData.companyName,
                taxId: formData.taxId,
                choice: formData.choice,
                pickUpPostalCode: formData.pickUpPostalCode,
                depositPostalCode: formData.depositPostalCode,
                elevator: formData.elevator,
                floor: formData.floor,

            },
        };
        setActiveStep(0)
        emailjs.send(emailData.service_id, emailData.template_id, emailData.template_params, emailData.user_id)
            .then((response) => {
                console.log('Email sent successfully', response);
                // You can reset the form or provide feedback to the user here
                setSubmitted(true)
            })
            .catch((error) => {
                console.error('Error sending email', error);
                // Handle error feedback here
                setSubmitted(false)
            });
    };

    return (
        <div style={{ maxWidth: 600, margin: 'auto' }} id='estimate'>
            <Typography variant='h4' gutterBottom sx={{ textAlign: 'center' }}>{t('estimate.title')}</Typography>

            {activeStep > 0 && (
                <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={t('estimate.' + label)}>
                            <StepLabel>{t('estimate.' + label)}</StepLabel>
                        </Step>
                    ))}
                </Stepper>)}
            {activeStep === 0 && (
                <Box
                    sx={{
                        display: 'flex',
                        alignSelf:'center',
                        justifySelf:'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '15vw', // Adjust the height as needed
                        width: {xs: '40wv', md:'80wv'},
                        borderRadius: '15px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        padding: '20px',
                        textAlign: 'center', // Ensures text and button are centered
                    }}
                >
                    <Typography variant='h6'>{t('estimate.startText')}</Typography>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setActiveStep(1)}
                        sx={{
                            padding: '10px 20px',
                            fontSize: '16px',
                            textTransform: 'none',
                            borderRadius: '30px', // Rounded button edges
                            my: '15px'
                        }}
                    >
                        {t('estimate.start')}
                    </Button>
                </Box>
            )}
            {/*activeStep === 1 && (
                <div style={{ marginTop: 20 }}>
                    <Typography variant="h6" gutterBottom>
                        {t('estimate.choose_service')}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Card
                                onClick={() => handleChoice('move')}
                                sx={{
                                    minHeight: '20.5vh',
                                    cursor: 'pointer',
                                    transition: '0.3s',
                                    '&:hover': {
                                        backgroundColor: '#f0f0f0',
                                    }
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h5">{t('estimate.move')}</Typography>
                                    <Typography variant="body2">{t('estimate.move_description')}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card
                                onClick={() => handleChoice('letgo')}
                                sx={{
                                    minHeight: '20vh',
                                    cursor: 'pointer',
                                    transition: '0.3s',
                                    '&:hover': {
                                        backgroundColor: '#f0f0f0',
                                    }
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h5">{t('estimate.let_go')}</Typography>
                                    <Typography variant="body2">{t('estimate.let_go_description')}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
            )*/}

            {/* Step 2: Postal code input based on selection */}
            {activeStep === 1 && (
                <div style={{ marginTop: 20 }}>
                    <Typography variant="h6" gutterBottom>
                        {t('estimate.enter_postal_codes')}
                    </Typography>

                    {errors.pickUpPostalCode && <Alert severity="error">{errors.pickUpPostalCode}</Alert>}
                    {errors.depositPostalCode && <Alert severity="error">{errors.depositPostalCode}</Alert>}

                    <TextField
                        label={t('estimate.pick_up_postal_code')}
                        variant="outlined"
                        fullWidth
                        name="pickUpPostalCode"
                        value={formData.pickUpPostalCode}
                        onChange={handleInputChange}
                        margin="normal"
                        error={Boolean(errors.pickUpPostalCode)}
                        helperText={errors.pickUpPostalCode}
                    />

                    {formData.choice === 'move' && (
                        <TextField
                            label={t('estimate.deposit_postal_code')}
                            variant="outlined"
                            fullWidth
                            name="depositPostalCode"
                            value={formData.depositPostalCode}
                            onChange={handleInputChange}
                            margin="normal"
                            error={Boolean(errors.depositPostalCode)}
                            helperText={errors.depositPostalCode}
                        />
                    )}

                    <div style={{ marginTop: 20 }}>
                        <Button onClick={handleBack} style={{ marginRight: 10 }}>
                            {t('estimate.back')}
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleNext}>
                            {t('estimate.next')}
                        </Button>
                    </div>
                </div>
            )}

            {/* Step 3: Select furniture and estimate price */}
            {activeStep === 2 && (
                <div style={{ marginTop: 20, justifyContent: 'center', alignContent: 'center' }}>
                    <Typography variant="h6" gutterBottom>
                        {t('estimate.select_furniture')}
                    </Typography>

                    <EstimationForm ref={estimationFormRef} onDataChange={handleEstimationData} />

                    {estimatedPrice && (
                        <Alert severity="success" style={{ marginTop: 20 }}>
                            {t('estimate.estimated_price')}: {estimatedPrice} €
                        </Alert>
                    )}

                    <div style={{ marginTop: 20 }}>
                        <Button onClick={handleBack} style={{ marginRight: 10 }}>
                            {t('estimate.back')}
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleEstimate} style={{ marginRight: 10 }}>
                            {t('estimate.estimate')}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleRequestQuote}
                            disabled={!isConfirmed}
                        >
                            {t('estimate.request_quote')}
                        </Button>
                    </div>
                </div>
            )}



            {/* Step 4: Personal Details */}
            {activeStep === 3 && (
                <div style={{ marginTop: 20 }}>
                    <Typography variant="h6" gutterBottom>
                        {t('estimate.additional_details')}
                    </Typography>

                    <TextField
                        label={t('estimate.full_name')}
                        variant="outlined"
                        fullWidth
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleInputChange}
                        margin="normal"
                        required
                    />

                    <TextField
                        label={t('estimate.phone')}
                        variant="outlined"
                        fullWidth
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                        margin="normal"
                        required
                    />

                    <TextField
                        label={t('estimate.email')}
                        variant="outlined"
                        fullWidth
                        name="emailAddress"
                        value={formData.emailAddress}
                        onChange={handleInputChange}
                        margin="normal"
                        required
                    />

                     <TextField
                        label={t('estimate.complete_address_pickup')}
                        variant="outlined"
                        fullWidth
                        name="completeAddressPickUp"
                        value={formData.completeAddressPickUp}
                        onChange={handleInputChange}
                        margin="normal"
                    />
                    {formData.choice === "move" && <TextField
                        label={t('estimate.complete_address_pickup')}
                        variant="outlined"
                        fullWidth
                        name="completeAddressPickUp"
                        value={formData.completeAddressPickUp}
                        onChange={handleInputChange}
                        margin="normal"
                    />}
                    {formData.choice === "move" && <TextField
                        label={t('estimate.complete_address_deposit')}
                        variant="outlined"
                        fullWidth
                        name="completeAddressDeposit"
                        value={formData.completeAddressDeposit}
                        onChange={handleInputChange}
                        margin="normal"
                    />}


                    {(
                        <>
                            <TextField
                                label={t('estimate.company')}
                                variant="outlined"
                                fullWidth
                                name="companyName"
                                value={formData.companyName}
                                onChange={handleInputChange}
                                margin="normal"
                                required
                            />

                            {/* <TextField
                                label={t('estimate.tax_id')}
                                variant="outlined"
                                fullWidth
                                name="taxId"
                                value={formData.taxId}
                                onChange={handleInputChange}
                                margin="normal"

                            /> */}
                        </>
                    )}

                    <div style={{ marginTop: 20 }}>
                        <Button onClick={handleBack} style={{ marginRight: 10 }}>
                            {t('estimate.back')}
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleSubmit}>
                            {t('estimate.submit')}
                        </Button>
                    </div>
                </div>

            )}

            {submitted && (
                <Alert severity="success" style={{ marginTop: 20 }}>
                    {t('estimate.quote_request_valid')}
                </Alert>
            )}
            {submitted !== null && !submitted && (
                <Alert severity="success" style={{ marginTop: 20 }}>
                    {t('estimate.quote_request_invalid')}
                </Alert>
            )}
        </div>

    );
};

export default EstimationSection;

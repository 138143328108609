import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './Services.css'; // Import your CSS file for custom styling

const Services = () => {
  const { t } = useTranslation();
  const services = t('services.services', { returnObjects: true });

  // Define alternating background colors for each section
  const colors = ['#f1f1f1', '#f0f0f0', '#e0f7fa', '#e8f5e9'];

  return (
    <div>
      <Container maxWidth="md">
        <Typography variant="h4" align="center" gutterBottom sx={{padding:'30px'}}>
          {t('services.title')}
        </Typography>

        {services.map((service, index) => (
          <Box
            key={index}
            sx={{
              backgroundColor: colors[index % colors.length],
              padding: 6,                   // Increase padding for spacious feel
              marginY: 4,                    // Larger margin between sections
              textAlign: 'center',           // Center-align content
              borderRadius: 1,               // Optional: Lightly rounded corners
            }}
          >
            <Typography variant="h5" gutterBottom>
              {service.title}
            </Typography>
            <Typography variant="body1" sx={{ maxWidth: '600px', margin: 'auto' }}>
              {service.description}
            </Typography>
          </Box>
        ))}
      </Container>
    </div>
  );
};

export default Services;

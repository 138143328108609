import React from 'react';
import { Box, Typography, Grid, Card, CardContent, Avatar, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SpeedIcon from '@mui/icons-material/Speed';
import ForestIcon from '@mui/icons-material/Forest';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import AccessibilityIcon from '@mui/icons-material/Accessibility';

const WhyUs = () => {
  const { t } = useTranslation();

  const reasons = [
    {
      icon: <SpeedIcon fontSize="large" />,
      title: t('why_us.fast_service'),
      description: t('why_us.fast_service_description'),
    },
    {
      icon: <ForestIcon fontSize="large" />,
      title: t('why_us.trusted_company'),
      description: t('why_us.trusted_company_description'),
    },
    {
      icon: <SettingsSuggestIcon fontSize="large" />,
      title: t('why_us.affordable_rates'),
      description: t('why_us.affordable_rates_description'),
    },
    {
      icon: <AccessibilityIcon fontSize="large" />,
      title: t('why_us.associations'),
      description: t('why_us.associations_description'),
    },
  ];

  return (
    <Container id='Whyus'>
    <Box sx={{ padding: 4, textAlign: 'center' }} id="why-us">
      <Typography variant="h4" gutterBottom>
        {t('why_us.title')}
      </Typography>
      <Typography variant="subtitle1" paragraph>
        {t('why_us.subtitle')}
      </Typography>
      <Grid container spacing={3}>
        {reasons.map((reason, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card elevation={4} sx={{ padding: 2, minHeight: '40vh', maxHeight:{xs:'60vh',md:'40vh'} ,transition: 'transform 0.3s, box-shadow 0.3s',
              '&:hover': {
                transform: 'scale(1.05)', // Slightly scale the card on hover
                boxShadow: 6, // Increase shadow on hover
              },}}>
              <CardContent>
                <Avatar sx={{ bgcolor: 'secondary.main', margin: 'auto' }}>
                  {reason.icon}
                </Avatar>
                <Typography variant="h6" gutterBottom sx={{ marginTop: 2 }}>
                  {reason.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {reason.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
    </Container>
  );
};

export default WhyUs;

import React, { useState } from 'react';
import { Button, TextField, Container, Typography, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import emailjs from 'emailjs-com'; // Import EmailJS
import 'react-international-phone/style.css';
const ContactForm = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  const [errors, setErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);

  // Function to validate email and phone number
  const validate = () => {
    let tempErrors = {};
    const emailPattern = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
    const phonePattern = /^[0-9]{10,15}$/;

    if (!formData.name) tempErrors.name = 'Name is required';
    if (!emailPattern.test(formData.email)) tempErrors.email = 'Invalid email format';
    if (!phonePattern.test(formData.phone)) tempErrors.phone = 'Phone number should be 10-15 digits long';
    if (!formData.message) tempErrors.message = 'Message is required';

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0; // No errors
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    if (validate()) {
      // Send email using EmailJS
      emailjs.send('service_7j8oe9y', 'template_yefrvdf', formData, 'l9-W6dMyV2juDfhtY')
        .then(() => {
          setSubmitted(true);
          setFormData({ name: '', email: '', phone: '', message: '' }); // Clear form

        })
        .catch(error => {
          console.error('Error sending email: ', error);
          alert('Failed to send message. Please try again later.');
        });
    }
  };

  return (
    <Container maxWidth="sm" id="contact" sx={{padding:'20px'}}>
      <Typography variant="h4" align="center" gutterBottom>
        {t('contact.title')}
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label={t('contact.name')}
          name="name"
          value={formData.name}
          onChange={handleChange}
          error={!!errors.name}
          helperText={errors.name}
          margin="normal"
          required
        />

        <TextField
          fullWidth
          label={t('contact.email')}
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          error={!!errors.email}
          helperText={errors.email}
          margin="normal"
          required
        />

        <TextField
          fullWidth
          label={t('contact.phone')}
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          error={!!errors.phone}
          helperText={errors.phone}
          margin="normal"
          required
        />



        <TextField
          fullWidth
          label="Message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          error={!!errors.message}
          helperText={errors.message}
          margin="normal"
          multiline
          rows={4}
          required
        />




        <Button type="submit" variant="contained" color="secondary" fullWidth>
          {t('contact.submit')}
        </Button>
        {submitted && <Alert severity="success">{t('contact.success')}</Alert>}
      </form>
    </Container>
  );
};

export default ContactForm;

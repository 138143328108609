import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton, Menu, MenuItem, ListItemIcon } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';

// Import flag images
import ukFlag from '../assets/Flag_of_the_UK.png';
import frFlag from '../assets/Flag_of_France.png';

const LanguageDropdown = () => {
  const { i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    handleClose(); // Close the menu after selection
  };

  return (
    <div>
      <IconButton onClick={handleClick} color="inherit">
        <LanguageIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => changeLanguage('en')}>
          <ListItemIcon>
            <img src={ukFlag} alt="UK Flag" style={{ width: 20, height: 20, marginRight: 10, borderRadius: '50%' }} />
          </ListItemIcon>
          English
        </MenuItem>
        <MenuItem onClick={() => changeLanguage('fr')}>
          <ListItemIcon>
            <img src={frFlag} alt="French Flag" style={{ width: 20, height: 20, marginRight: 10, borderRadius: '50%' }} />
          </ListItemIcon>
          Français
        </MenuItem>
      </Menu>
    </div>
  );
};

export default LanguageDropdown;

import React, { useState } from 'react';
import { AppBar, Toolbar, Button, IconButton, Drawer, Box, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LanguageDropdown from './LanguageDropdDown'
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../assets/logo.png'; // Adjust the path based on your file structure

const Header = () => {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const navLinks = [
    { label: t('nav.home'), href: '/#home' },
    { label: 'Services', href: '/services' },
    { label: t('nav.estimate'), href: '/#estimate' },
    { label: t('nav.process'), href: '/process' },
    { label: t('nav.testimonials'), href: '/#testimonials' },
    { label: t('nav.contact'), href: '/#contact' },
  ];

  const handleNavigation = (event, href) => {
    event.preventDefault();
    const isAnchor = href.startsWith('/#');
    const target = href.replace('/#', '#');

    if (isAnchor) {
      const currentPath = location.pathname;

      if (currentPath !== '/') {
        // Navigate to home first
        navigate('/');
        // Wait for the navigation to complete, then scroll
        setTimeout(() => {
          const element = document.querySelector(target);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }, 500); // Adjust timeout if needed
      } else {
        // If already on the homepage, scroll directly
        const element = document.querySelector(target);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }
    } else {
      // Navigate to another page
      navigate(href);
    }

    // Close the drawer if open
    setDrawerOpen(false);
  };

  return (
    <>
      {/* AppBar renders only if drawer is not open */}
      {!drawerOpen && (
        <AppBar position="sticky">
          <Toolbar sx={{ justifyContent: 'center', padding: '15px' }}>
          <Box
              component="img"
              src={logo}
              alt="Procollecte Logo"
              sx={{
                scale:4,
                height: 40, // Adjust the height as needed
                marginX: '30px',
                display: { xs: 'flex', md: 'block' },
                filter: 'brightness(0) invert(1)', // White overlay effect
              }}
            />
            <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', alignItems:'center' }}>
              {navLinks.map((link, index) => (
                <Button key={index} color="inherit"  onClick={(e) => handleNavigation(e, link.href)} sx={{ margin: '10px' }}>
                  {link.label}
                </Button>
              ))}
              <LanguageDropdown />
            </Box>

            {/* Burger Icon for small screens */}
            <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end', flexGrow: 1 }}>
              <IconButton color="inherit" onClick={toggleDrawer}>
                <MenuIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
      )}

      {/* Drawer */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        
        <Box
          sx={{
            width: 250,
            paddingTop: '15px',
          }}
          
          onKeyDown={toggleDrawer}
        >
          <List>
            {navLinks.map((link, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton component="a" href={link.href} onClick={()=>toggleDrawer()}>
                  <ListItemText primary={link.label} />
                </ListItemButton>
              </ListItem>
            ))}
            <ListItem disablePadding>
              <LanguageDropdown />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default Header;

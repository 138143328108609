import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Components/Header';
import Footer from './Components/Footer';
import HeroSection from './Components/HeroSection';
import WhyUs from './Components/WhyUs';
import Services from './Components/Services'; // Services component can be reused
import Testimonials from './Components/Testimonials';
import ContactForm from './Components/ContactForm';
import EstimationSection from './Components/EstimationSection';
import OurProcess from './Components/OurProcess';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import lightTheme from './theme/LightTheme'

const App = () => {
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <Router>
        <Helmet>
          <script type="application/ld+json">
            {`
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "PROCOLLECTE",
        "url": "https://www.procollecte.com",
        "logo": "https://www.procollecte.com/logo.png",
        "sameAs": [
          "https://www.facebook.com/procollecte",
          "https://www.instagram.com/procollecte"
        ],
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+123456789",
          "contactType": "Customer Service"
        }
      }
    `}
          </script>
          <title>PROCOLLECTE - Bulky Item Removal and Recycling</title>
          <link rel="alternate" href="https://www.procollecte.com" hreflang="en" />
          <link rel="alternate" href="https://www.procollecte.com/" hreflang="fr" />
          <link rel="alternate" href="https://www.procollecte.com" hreflang="fr" />
          <meta name="description" content="PROCOLLECTE offers efficient waste collection and recycling services. Contact us for personalized solutions and an immediate estimation." />
          <meta name="description" content="PROCOLLECTE propose des services efficaces de collecte et de recyclage des ecombrants. Contactez-nous pour des solutions personnalisées et une estimation immédiate et un devis gratuit sans engagement."/>
          <meta name="keywords" content="waste collection, recycling, environmentally friendly, service" />
          {/* Open Graph tags */}
          <meta property="og:title" content="PROCOLLECTE - Collecte d'encombrants pour les professionnels" />
          <meta property="og:description" content="Contact us for tailored waste collection solutions." />
          <meta property="og:description" content="Contactez nous pour un service de collecte d'encombrants détaillé orienté vers vos besoins" />
          <meta property="og:image" content="https://procollecte.com/icone.svg" />
          <meta property="og:url" content="https://procollecte.com" />
          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <title>Procollecte</title>
          <meta name="description" content="We offer professional bulky item removal services to make your spaces free and stress-free." />
          <meta name="description" content="Procollecte offre un service de collecte des encombrants pour libérer vos espaces sans casse tête ." />

        </Helmet>
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <HeroSection />
              <WhyUs />
              <EstimationSection />
              <Testimonials />
              <ContactForm />
            </>
          } />
          <Route path="/services" element={<Services />} />
          <Route path="/Process" element={<OurProcess />} />
        </Routes>
        <Footer />
      </Router>
    </ThemeProvider>
  );
};

export default App;
